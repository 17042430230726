body,
html {
  margin: 0px;
  padding: 0px;
  background: #000;
}

* {
  box-sizing: border-box;
}
